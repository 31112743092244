const palette = {
  primary: {
    main: '#08517e',
    dark: '#063d5f',
    contrastText: '#fff',
    backgroundColor: '#f2f4f7',
  },
  secondary: {
    main: '#e7e2e2',
  },
};

export default palette;
