// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-menu-dropdown {
  cursor: pointer;
  align-items: center;
  background-color: #f2f4f7;
  padding: 0px 20px;
  border-right: 1px solid #999;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  width: 250px;
  text-overflow: ellipsis;
}

.dropdown-label {
  font-size: 16px !important;
  color: #999;
}

@media screen and (min-width: 768px) {
  .topbar__search {
    display: flex;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/topbar/topbar-search-menu.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,mBAAA;EACA,yBCFiB;EDGjB,iBAAA;EACA,4BAAA;EACA,4BAAA;EACA,+BAAA;EACA,YAAA;EACA,uBAAA;AADF;;AAGA;EACE,0BAAA;EACA,WCRkB;ADQpB;;AAGA;EACE;IACE,aAAA;EAAF;AACF","sourcesContent":["@import '../variables.scss';\n\n.search-menu-dropdown {\n  cursor: pointer;\n  align-items: center;\n  background-color: $background-color;\n  padding: 0px 20px;\n  border-right: 1px solid $sub-heading-color;\n  border-top-left-radius: 30px;\n  border-bottom-left-radius: 30px;\n  width: 250px;\n  text-overflow: ellipsis;\n}\n.dropdown-label {\n  font-size: 16px !important;\n  color: $sub-heading-color;\n}\n\n@media screen and (min-width: 768px) {\n  .topbar__search {\n    display: flex;\n  }\n}\n","$primary-color: #08517e;\n$primary-hover-color: #063d5f;\n$primary-hover-color-light: #e8f2f8;\n$background-color: #f2f4f7;\n$background-color-white: #ffffff;\n$card-background-color: #ffffff;\n$heading-color: #000;\n$sub-heading-color: #999;\n$color-additional: #999999;\n$color-accent: #38a7ec;\n$required-color: #e54643;\n$text-color: #fff;\n$topbar-logo: url('../../public/logo.png');\n$auth-background-image: url('../../public/auth-background.jpg');\n\n// input styles\n$input-border-color:#afacac;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
